.cardTitle:not(.lastCardTitle) {
    margin-bottom: 15px;
}

.cardTitle > .ant-card-head {
    background-color: #f0f2f5;
    font-weight: bold;
}

.videos .video-section {
    padding-bottom: 25px;
}

.videos .video-section .video-iframe {
    min-height: 240px;
    min-width: 240px;
    position: relative;
}