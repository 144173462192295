.popup-div {
    overflow: auto;
    max-height: 300px;
    max-width: 400px;
    --lateralMargins: 5px;
    margin-left: var(--lateralMargins);
    margin-right: var(--lateralMargins);
}

.popup-title {
    text-align: left;
    font-weight: bold;
    font-size: 0.9em !important;
}

.popup-paragraph {
    margin: 0;
}

.popup-label {
    font-size: 0.8em !important;
    font-weight: bold;
}

.popup-value {
    font-size: 0.8em !important;
}