/*
VA-Team
 */

button.mapbox-ctrl.button {
    width: 100%;
    height: 100%;
    background: #fff;
    position: relative;
    border-radius: 6px;
    z-index: 9;
    padding: 0;
    min-height: 40px;
    box-sizing: border-box;
    display: block;
    border-color: #D7D6DD;
    opacity: 1;
}

span.mapbox-ctrl-icon.span {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    background-size: 27px 27px;
    background-repeat: no-repeat;
    background-position: 50%;
    z-index: 10;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


