td.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last svg:hover {
    cursor: pointer;
}

.ql-container.ql-disabled .ql-tooltip {
    visibility: hidden;
    height: 0;
}

.ant-card-type-inner .ant-card-head,
.quill .ql-toolbar,
.ant-tabs-nav-wrap{
    background-color:  #e6eff982;
}

.dragAndDropForm li {
    margin-bottom: 10px;
    padding: 10px;
    list-style: none;
    border-radius: 5px;
}

.formData .ant-form-item.buttonBlock button{
    background-color: #c0c5ca82;
}

.dragAndDropForm .form-fields-items {
    padding: 0px;
    margin: 0 25px 0 0;
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow-y: scroll;
}

.dragAndDropForm .form-fields-items li {
    border: 0.5px solid rgb(0, 33, 64);
    background-color: #001529;
    color: #FFF;
    outline: none;
}

.dragAndDropForm .form-fields-items li span svg {
    filter: invert(1);
}

.dragAndDropForm .form-fields-items li:not([draggable="false"]) {
    opacity: 0.25;
}

.dragAndDropForm .form-fields-items li:empty {
    display: none !important;
}

.dragAndDropForm li.draggable-item-block {
    background-color: #e6eff982;
    padding: 50px 0px;
}

.dragAndDropForm .draggable-item-block-label {
    margin: auto auto auto 0px;
    padding-bottom: 10px;
    text-align: left;
}

.dragAndDropForm .draggable-item-block-input {
    padding-left: 40px;
    padding-bottom: 0px;
    margin: auto auto 10px 0px;
}

.dragAndDropForm .form-fields-items li:hover {
    background-color: rgba(0, 33, 64, 0.5);
}

.dragAndDropForm .form-fields-items li:hover:not([draggable="false"]) {
    background-color: initial;
    color: initial;
    cursor: not-allowed;
}

.dragAndDropForm li.drag-item-msg {
    border: none;
    text-align: center;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    padding: 15px 0;
}

.dragAndDropForm li.drag-item-msg:hover {
    background: none;
    color: initial;
}

.dragAndDropForm li:last-child {
    margin-bottom: 0px;
}

.dragAndDropForm .form-fields-items-copy~li {
    transform: none !important;
    z-index: 999999999;
}


.dragAndDropForm .form-fields-items-copy {
    margin-bottom: 10px !important;
    padding: 10px !important;
    background: rgb(0, 33, 64);
    border: 1px solid rgb(0, 33, 64) !important;
    color: #FFF;
    list-style: none;
    opacity: 0.5 !important;
    border-radius: 5px;
    z-index: 999999;
}

.dragAndDropForm .form-fields-items-copy~[data-rbd-placeholder-context-id] {
    display: none !important;
}

.dragAndDropForm form#form {
    min-height: 1000px;
}

.dragAndDropForm .form-items {
    padding: 25px;
    min-height: 50px;
    border: 2px dashed transparent;
    margin: 0 0 500px 0;
    position: relative;
    border-color: #d9d9d9;
    /*margin-left: 10px;
    background: #fafafa;
    box-shadow: 0 0 2px 1px rgb(0 0 0 / 10%);
    padding: 50px;*/
}

.dragAndDropForm .form-items:hover,
.dragAndDropForm .ql-toolbar.ql-snow,
.dragAndDropForm .ql-container.ql-snow {
    border-color: #d9d9d9;
    border-bottom-width: 2px;
}

.dragAndDropForm .form-items li.draggable-item {
    background: #FFF;
    box-shadow: 0 0 2px 1px rgb(0 0 0 / 10%);
}

.dragAndDropForm .form-fields-items-copy span.anticon {
    filter: invert(1);
}

.dragAndDropForm .form-items li.drag-item-buttons {
    text-align: center;
    border: none;
    background: none;
    padding: 0px
}

.dragAndDropForm .form-items li.draggable-item div[class*="-row"]:not(.draggable-item-row) {
    margin-bottom: 0px;
}

.dragAndDropForm .form-items .draggable-item-row {
    margin: auto 0;
    text-align: center;
    padding: 0 10px;
}

.dragAndDropForm .form-items .draggable-item-row-icon {
    font-size: 19px;
}

.dragAndDropForm .form-items .draggable-item-row-actions>*:not(:first-child) {
    margin-left: 10px;
}

.dragAndDropForm .form-items .draggable-item-row.textAlign-left {
    text-align: left;
}

.dragAndDropForm .form-items .draggable-item-row-hidden {
    display: none;
}

.dragAndDropForm .form-items .draggable-item-row-info .info-row {
    padding-top: 15px;
}

.dragAndDropForm .draggable-item-row-info {
    visibility: hidden;
    height: 0px;
}

.dragAndDropForm .editing-item-row.draggable-item-row-info {
    visibility: visible;
    height: auto;
}

.dragAndDropForm .collapse-panel {
    background-color: #FFF;
}

.dragAndDropForm .collapse-panel>p {
    cursor: pointer;
    font-size: 16px;
}

.dragAndDropForm .collapse-panel p span {
    margin-right: 5px;
    font-size: 20px;
    vertical-align: middle;
}

.dragAndDropForm .draggable-item-row-block,
.dragAndDropForm .generalInfoCollapsed {
    display: none;
}

.dragAndDropForm span.anticon {
    vertical-align: middle;
    margin-right: 5px;
}

.dragAndDropForm .modalInfo-file-container {
    min-width: 50%;
}

.dragAndDropForm .modalInfo-file-container .ant-modal-confirm-body > .anticon + .ant-modal-confirm-title + .ant-modal-confirm-content {
    margin-left: 0px;
}

.dragAndDropForm .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    margin-left: 32px;
}

.modalInfo-preview-container .ant-modal-confirm-title {
    font-size: 12px !important;
    display: inline-flex;
    margin: auto;
}

.modalInfo-preview-container .ant-modal-confirm-title svg {
    max-height: 22px;
    margin-right: 10px;
}

.modalInfo-preview-container .modalInfo-preview div>h3 {
    text-align: center;
    border-bottom: 1px solid #CCC;
}

.modalInfo-preview-container .modalInfo-preview div .textAlign-center {
    text-align: center;
}

.dragAndDropForm .form-items li.draggable-item .ant-upload.ant-upload-select-picture-card {
    border-radius: 20px;
}

.dragAndDropForm .form-items li.draggable-item .ant-upload.ant-upload-select-picture-card .uploadPhotoCeckboxButton {
    border: 0;
    background: none;
}

.dragAndDropForm .form-items li.draggable-item .ant-upload.ant-upload-select-picture-card .uploadPhotoCeckboxButton div {
    margin-top: 8;
}

button.ant-modal-close {
    background: #FFF;
    border-radius: 50%;
}

img.photoCheckboxModal {
    width: 100%;
}

.formItems .item .photoCheckbox .ant-checkbox-wrapper {
    display: inline-block;
}

/*.formItems .item .photoCheckbox .ant-checkbox-inner {
    position: absolute;
    left: 100%;
}*/

.formItems .item .photoCheckbox {
    margin: auto;
    text-align: center;
}

.formItems .item .photoCheckbox img {
    max-width: 150px;
    max-height: 150px;
    border-radius: 5px;
}
.formItems .item .photoCheckbox .ant-checkbox-checked::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
}
.formItems .item .photoCheckbox .ant-checkbox .ant-checkbox-inner {
    /*Colocar en  la mitad*/
    top: 75px;
    border-color: unset;
}

.formItems .item .photoCheckbox .ant-checkbox.ant-checkbox-checked + span img {
    /*border: 2px solid #1890ff;*/
}
